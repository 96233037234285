import React, { useState, useContext } from "react";

import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Card,
  CardContent,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
} from "@mui/material";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";
import { WebSocketContext } from "../../../providers/WebSocketContext";
import LoadingContext from "../../../providers/LoadingContext";

const CreateEvent = ({ showCreateEvent, setShowCreateEvent }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = null;
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.CreateEvent", logLevel);

  Logger.of("AdminDashboard.CreateEvent").info(
    "Rendering the CreateEvent component"
  );

  const { sendJsonMessage } = useContext(WebSocketContext);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({ name: false, description: false });

  const { setCreateEventLoading } = useContext(LoadingContext);

  function handleAdd(event) {
    let newErrors = { name: false, description: false };

    if (!name.trim()) {
      newErrors.name = true;
    }

    if (!description.trim()) {
      newErrors.description = true;
    }

    if (newErrors.name || newErrors.description) {
      setErrors(newErrors);
      return;
    }

    let newEvent = {
      name,
      description,
      joinCode: Math.floor(Math.random() * 100000),
      status: "stopped",
    };

    setCreateEventLoading(true);

    sendJsonMessage({
      action: "eventsCreate",
      event: newEvent,
    });

    setName("");
    setDescription("");
    setShowCreateEvent(!showCreateEvent);
  }

  function handleCancel(event) {
    setName("");
    setDescription("");
    setShowCreateEvent(!showCreateEvent);
  }

  function handleCreateEventClose(event) {
    setShowCreateEvent(!showCreateEvent);
  }

  return (
    <Dialog open={showCreateEvent} onClose={handleCreateEventClose}>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Create a new event</span>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setShowCreateEvent(!showCreateEvent);
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <CardContent style={{ paddingTop: "0px" }}>
        <Card variant="outlined">
          <CardContent>
            <FormControl variant="outlined" fullWidth>
              <TextField
                fullWidth
                error={!!errors.name}
                type="text"
                name="name"
                label="Event name"
                placeholder="Event name"
                onChange={(e) => setName(e.target.value)}
                required
                variant="outlined"
                helperText={errors.name ? "Enter a name for the event" : ""}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <TextField
                fullWidth
                error={!!errors.description}
                type="text"
                name="description"
                placeholder="Event description"
                onChange={(e) => setDescription(e.target.value)}
                label="Event description"
                required
                multiline
                rows={3}
                variant="outlined"
                helperText={
                  errors.description ? "Enter a description for the event" : ""
                }
                sx={{ marginTop: "10px" }}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" onClick={handleAdd}>
                Save event
              </Button>
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Dialog>
  );
};

export default CreateEvent;
