import React, { useContext } from "react";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { IconButton, CircularProgress, Typography } from "@mui/material";

import { Logger } from "react-logger-lib";

import { WebSocketContext } from "../../../providers/WebSocketContext";
import ConfigContext from "../../../providers/ConfigContext";
import LoadingContext from "../../../providers/LoadingContext";

const EventActions = ({ labEvent, setShowEditEvent }) => {
  const config = useContext(ConfigContext);
  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.EventActions", logLevel);

  Logger.of("AdminDashboard.EventActions").trace(
    "Rendering EventActions component"
  );

  const { sendJsonMessage } = useContext(WebSocketContext);
  const {
    updateEventLoading,
    deleteEventLoading,
    setDeleteEventLoading,
    startEventLoading,
    setStartEventLoading,
    stopEventLoading,
    setStopEventLoading,
  } = useContext(LoadingContext);

  function handleEditEventClick(event) {
    setShowEditEvent(true);
  }

  function handleStartEventClick(event, eventId) {
    event.preventDefault();
    const confirmStart = window.confirm(
      "Are you sure you want to start this event? This will deploy all resources required for this event."
    );

    if (confirmStart) {
      let updatedEvent = {
        ...labEvent,
        status: "started",
        startTime: new Date(),
        endTime: null,
      };

      setStartEventLoading(eventId);
      sendJsonMessage({
        action: "eventsUpdate",
        event: updatedEvent,
      });
    }
  }

  function handleStopEventClick(event, eventId) {
    event.preventDefault();
    const confirmStop = window.confirm(
      "Are you sure you want to stop this event? This will tear down all resources created for this event."
    );
    if (confirmStop) {
      let updatedEvent = {
        ...labEvent,
        status: "stopped",
        endTime: new Date(),
      };

      setStopEventLoading(eventId);
      sendJsonMessage({
        action: "eventsUpdate",
        event: updatedEvent,
      });
    }
  }

  function handleDeleteEventClick(event, eventId) {
    event.preventDefault();
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this event?"
    );
    if (confirmDelete) {
      setDeleteEventLoading(eventId);
      sendJsonMessage({
        action: "eventsDelete",
        eventId: labEvent.id,
      });
    }
  }

  let loadingMessage = null;

  if (updateEventLoading === labEvent.id) {
    loadingMessage = "Updating...";
  } else if (deleteEventLoading === labEvent.id) {
    loadingMessage = "Deleting...";
  } else if (startEventLoading === labEvent.id) {
    loadingMessage = "Starting...";
  } else if (stopEventLoading === labEvent.id) {
    loadingMessage = "Stopping...";
  }

  return (
    <>
      {loadingMessage ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Typography>{loadingMessage}</Typography>
          <CircularProgress size="1rem" sx={{ marginLeft: "10px" }} />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "right" }}>
          {labEvent.status === "stopped" && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleEditEventClick(event, labEvent.id);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {labEvent.status === "started" ? (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleStopEventClick(event, labEvent.id);
              }}
            >
              <StopIcon />
            </IconButton>
          ) : (
            <>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleStartEventClick(event, labEvent.id);
                }}
              >
                <PlayArrowIcon />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteEventClick(event, labEvent.id);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EventActions;
