import React from "react";

import TeamScore from "./TeamScore";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const EventScoreboard = ({ event, teams }) => {
  return (
    <>
      <h3>{event.name}</h3>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Team Name</strong>
              </TableCell>
              <TableCell>
                <strong>Score</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams
              .filter((team) => team.eventId === event.id)
              .map((team) => (
                <TeamScore team={team} key={team.id} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EventScoreboard;
