import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";

import { Logger } from "react-logger-lib";

import ConfigContext from "../../providers/ConfigContext";
import { WebSocketContext } from "../../providers/WebSocketContext";
import { CircularProgress } from "@mui/material";

const Challenge = ({ challenge, teamId, teams, eventId }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("UserDashboard.Challenge.handleSubmit", logLevel);
  localStorage.setItem("UserDashboard.Challenge.useEffect", logLevel);

  const [answer, setAnswer] = useState("");
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const [showIncorrect, setShowIncorrect] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [errors, setErrors] = useState({ answer: "" });

  const team = teams.find((team) => team.id === teamId);

  const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.answer) {
        Logger.of("UserDashboard.Challenge.useEffect").trace(
          "Got an answer submission result: ",
          lastJsonMessage.answer
        );
        if (lastJsonMessage.answer.result) {
          setAnswerCorrect(lastJsonMessage.answer.result);
          setLoadingMessage(null);
        }
        setShowIncorrect(lastJsonMessage.answer.result === false);
        setLoadingMessage(null);
      }
    }
  }, [lastJsonMessage]);

  function handleSubmitAnswer(event) {
    let newErrors = { answer: "" };

    if (!answer.trim()) {
      newErrors.answer = "Answer is required";
    }

    if (newErrors.answer) {
      setErrors(newErrors);
      return;
    }

    setErrors(newErrors);

    setLoadingMessage("Submitting answer...");
    sendJsonMessage({
      action: "answer",
      teamId: teamId,
      eventId: eventId,
      challengeId: challenge.id,
      answer: answer ? answer : "",
    });
  }

  return (
    <div>
      <h2>{challenge.title}</h2>
      <p>{challenge.description}</p>
      <p>
        <strong>Instructions</strong>
      </p>
      <div>
        <ol>
          {challenge.instructions.map((instruction) => (
            <li key={instruction.id}>{instruction.description}</li>
          ))}
        </ol>
      </div>
      {team.challengesCompleted.includes(challenge.id) ? (
        <div>
          <p>
            Congratulations - you have completed this challenge! Check out the
            scoreboard.
          </p>
        </div>
      ) : challenge.answer ? (
        <div>
          {!answerCorrect ? (
            <>
              <label>Challenge answer:</label>{" "}
              <input
                type="text"
                name="answer"
                disabled={loadingMessage === null ? false : true}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <Button onClick={handleSubmitAnswer}>Submit answer</Button>
              {loadingMessage && (
                <CircularProgress size="1rem" sx={{ marginLeft: "10px" }} />
              )}
              {errors.answer && <p style={{ color: "red" }}>{errors.answer}</p>}
              {showIncorrect && <span>Incorrect answer</span>}
            </>
          ) : (
            <p>
              Congratulations - you have completed this challenge! Check out the
              scoreboard.
            </p>
          )}
        </div>
      ) : (
        <div>
          No answer required for this challenge - follow the instructions!
        </div>
      )}
    </div>
  );
};

export default Challenge;
