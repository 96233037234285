import React, { useState, useEffect, useContext, useRef } from "react";

import { Card, CardContent, Container, Tab, Tabs } from "@mui/material";

import AdminDashboard from "./components/AdminDashboard/Dashboard";
import UserScoreboard from "./components/Scoreboard/UserScoreboard";
import UserDashboard from "./components/UserDashboard/Dashboard";
import JoinEvent from "./components/UserDashboard/JoinEvent";

import ConfigContext from "./providers/ConfigContext";
import { WebSocketContext } from "./providers/WebSocketContext";

import { Logger } from "react-logger-lib";

const Dashboards = ({ currentUser, setCurrentUser, idToken }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("Dashboards", logLevel);
  localStorage.setItem("Dashboards.render", logLevel);
  localStorage.setItem("Dashboards.useEffect", logLevel);
  localStorage.setItem("Dashboards.useEffect.fetchData", logLevel);

  Logger.of("Dashboards").trace("Rendering Dashboards component");
  const [activeTab, setActiveTab] = useState("dashboard");
  const [teams, setTeams] = useState(null);

  const [challengeDifficulties] = useState();
  const [requestedEvents, setRequestedEvents] = useState(false);
  const [requestedTeams, setRequestedTeams] = useState(false);
  const [events, setEvents] = useState(null);

  const { sendJsonMessage, readyState, lastJsonMessage } =
    useContext(WebSocketContext);
  const readyStateRef = useRef(readyState);

  Logger.of("Dashboards").trace("currentUser:", currentUser);

  useEffect(() => {
    readyStateRef.current = readyState;
  }, [readyState]);

  useEffect(() => {
    if (readyStateRef.current === WebSocket.OPEN) {
      if (!teams && !requestedTeams && currentUser.eventId) {
        Logger.of("Dashboards.useEffect").trace(
          "Requesting data from the websocket for eventId:",
          currentUser.eventId
        );
        setRequestedTeams(true);
        sendJsonMessage({ action: "teamsGet", eventId: currentUser.eventId });
      }
      if (!events && !requestedEvents) {
        Logger.of("Dashboards.useEffect").trace(
          "Requesting data from the websocket for all running and joinable events"
        );
        sendJsonMessage({
          action: "eventsGet",
        });
        setRequestedEvents(true);
      }
    }
  }, [
    currentUser,
    sendJsonMessage,
    events,
    requestedEvents,
    requestedTeams,
    teams,
  ]);

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.events) {
        Logger.of("UserDashboard.Dashboards.useEffect").trace(
          "Setting events to: ",
          lastJsonMessage.events
        );
        setEvents(lastJsonMessage.events);
      }
      if (lastJsonMessage.teams) {
        Logger.of("UserDashboard.Dashboards.useEffect").trace(
          "Setting teams to: ",
          lastJsonMessage.teams
        );
        setTeams(lastJsonMessage.teams);
      }
      if (lastJsonMessage.user) {
        Logger.of("UserDashboard.Dashboards.useEffect").trace(
          "Setting user to: ",
          lastJsonMessage.user
        );
        setCurrentUser(lastJsonMessage.user);
      }
    }
  }, [lastJsonMessage, setEvents, setTeams, setCurrentUser]);

  Logger.of("Dashboards").trace("teams:", teams);

  return (
    <CardContent>
      {currentUser.isAdmin ? (
        <div>
          <AdminDashboard />
        </div>
      ) : (
        <>
          {!currentUser.eventId && events && (
            <JoinEvent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              events={events}
            />
          )}
          {currentUser.eventId && (
            <Container maxWidth="sm">
              <Card variant="outlined">
                <CardContent>
                  <div>
                    <Tabs
                      value={activeTab}
                      onChange={(event, newValue) => setActiveTab(newValue)}
                      centered
                    >
                      <Tab label="User Dashboard" value="dashboard" />
                      <Tab label="Scoreboard" value="scoreboard" />
                    </Tabs>
                    {activeTab === "dashboard" && teams && events && (
                      <UserDashboard
                        events={events}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        teams={teams}
                        setTeams={setTeams}
                        challengeDifficulties={challengeDifficulties}
                        idToken={idToken}
                      />
                    )}
                    {activeTab === "scoreboard" && (
                      <UserScoreboard teams={teams} currentUser={currentUser} />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Container>
          )}
        </>
      )}
    </CardContent>
  );
};

export default Dashboards;
