import React, { useContext } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import ConfigContext from "../../../providers/ConfigContext";
import LoadingContext from "../../../providers/LoadingContext";
import { WebSocketContext } from "../../../providers/WebSocketContext";
import { Logger } from "react-logger-lib";

const EventChallengeActions = ({
  challenge,
  labEvent,
  validatedBackgroundColour,
  pendingBackgroundColour,
}) => {
  const config = useContext(ConfigContext);
  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem(
    "AdminDashboard.EventChallengeActions.handleDeleteChallengeClick",
    logLevel
  );

  const { sendJsonMessage } = useContext(WebSocketContext);

  const { setUnassignChallengeLoading } = useContext(LoadingContext);

  function handleDeleteChallengeClick(event, challengeId) {
    event.preventDefault();

    const confirmDelete = window.confirm(
      "Are you sure you want to unassign this challenge from the event?"
    );
    if (!confirmDelete) {
      return;
    }

    let updatedLabEvent = {
      ...labEvent,
      challenges: labEvent.challenges.filter(
        (challenge) => challenge.id !== challengeId
      ),
    };

    Logger.of(
      "AdminDashboard.EventChallengeActions.handleDeleteChallengeClick"
    ).trace("updatedLabEvent: ", updatedLabEvent);

    setUnassignChallengeLoading({
      eventId: labEvent.id,
      challengeId: challengeId,
    });
    sendJsonMessage({
      action: "eventsUpdate",
      event: updatedLabEvent,
    });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          backgroundColor:
            challenge.status === "validated"
              ? validatedBackgroundColour
              : pendingBackgroundColour,
        }}
      >
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteChallengeClick(event, challenge.id);
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      </div>
    </>
  );
};

export default EventChallengeActions;
