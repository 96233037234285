import React from "react";

import { signOut } from "aws-amplify/auth";

import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import { Logger } from "react-logger-lib";

localStorage.setItem("Authentication.SignOut.handleSignOut", "ERROR");

const SignOut = ({
  cognitoUser,
  setCurrentUser,
  setCognitoUser,
  setIdToken,
  setSocketUrl,
}) => {
  async function handleSignOut() {
    try {
      await signOut();
      setCurrentUser(null);
      setCognitoUser(null);
      setIdToken(null);
      setSocketUrl(null);
    } catch (error) {
      Logger.of("Authentication.SignOut.handleSignOut").error(
        "Error signing out: ",
        error
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <Typography align="right" mr={2}>
        Logged in as {cognitoUser.username}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleSignOut}>
        Logout
      </Button>
    </div>
  );
};

export default SignOut;
