import React, { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { TableCell } from "@mui/material";

import { WebSocketContext } from "../../../providers/WebSocketContext";
import LoadingContext from "../../../providers/LoadingContext";

const AssignChallenge = ({
  labEvent,
  unassignedChallenges,
  showChallengeList,
  setShowChallengeList,
}) => {
  const [selectedChallengeId, setSelectedChallengeId] = useState("");

  const { sendJsonMessage } = useContext(WebSocketContext);

  const { setAssignChallengeLoading } = useContext(LoadingContext);

  function handleAssignChallenge(event) {
    if (selectedChallengeId) {
      if (selectedChallengeId === 0) {
        return;
      }
      const challengeToAdd = unassignedChallenges.find(
        (challenge) => challenge.id === selectedChallengeId
      );
      let updatedLabEvent = {
        ...labEvent,
        challenges: [...labEvent.challenges, challengeToAdd],
      };

      setAssignChallengeLoading({
        eventId: labEvent.id,
        challengeId: selectedChallengeId,
      });
      sendJsonMessage({
        action: "eventsUpdate",
        event: updatedLabEvent,
      });
    }
    setSelectedChallengeId(0);
    setShowChallengeList(false);
  }

  function handleCancelNewChallenge(event) {
    setSelectedChallengeId(0);
    setShowChallengeList(!showChallengeList);
  }

  function handleSelectChange(event) {
    setSelectedChallengeId(event.target.value);
  }

  return (
    <>
      <TableCell></TableCell>
      <TableCell colSpan={3}>
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "15px", marginTop: "15px" }}
        >
          <InputLabel id="choose-challenge">
            Choose an available challenge
          </InputLabel>

          <Select
            fullWidth
            label="Choose an available challenge"
            value={selectedChallengeId}
            onChange={handleSelectChange}
            style={{ backgroundColor: "white" }}
          >
            <MenuItem value="" disabled></MenuItem>
            {unassignedChallenges.map((challenge) => (
              <MenuItem key={challenge.id} value={challenge.id}>
                {challenge.name} - {challenge.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={handleAssignChallenge}>
          <DoneIcon />
        </IconButton>
        <IconButton onClick={handleCancelNewChallenge}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </>
  );
};

export default AssignChallenge;
