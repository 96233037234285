import { TableCell, TableRow } from "@mui/material";
import React from "react";

const TeamScore = ({ team }) => {
  return (
    <>
      <TableRow>
        <TableCell>{team.name}</TableCell>
        <TableCell>{team.score}</TableCell>
      </TableRow>
    </>
  );
};

export default TeamScore;
