import React, { useState, useContext } from "react";

import Challenge from "./Challenge";

import "./ChallengeList.css"; // Import the CSS file

import ConfigContext from "../../providers/ConfigContext";

import { Logger } from "react-logger-lib";

const ChallengeList = ({ event, teamId, teams, eventId }) => {
  const config = useContext(ConfigContext);

  localStorage.setItem("UserDashboard.ChallengeList", config.logLevel);

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (id) => {
    setActiveIndex(activeIndex === id ? null : id);
  };

  Logger.of("UserDashboard.ChallengeList").trace("event:", event);

  return (
    <div className="accordion">
      <h2>Challenges</h2>
      {event.status === "started" ? (
        event.challenges
          .filter((challenge) => challenge.status === "validated")
          .map((challenge) => (
            <div key={challenge.id} className="accordion-item">
              <div
                className="accordion-title"
                onClick={() => onTitleClick(challenge.id)}
              >
                <h3>{challenge.name}</h3>
              </div>
              {activeIndex === challenge.id && (
                <div className="accordion-content">
                  <Challenge
                    challenge={challenge}
                    teamId={teamId}
                    teams={teams}
                    eventId={eventId}
                  />
                </div>
              )}
            </div>
          ))
      ) : (
        <div>
          <p>Your event has not started yet</p>
        </div>
      )}
    </div>
  );
};

export default ChallengeList;
