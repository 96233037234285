import React, { useState } from "react";
import LoadingContext from "./LoadingContext";

const LoadingProvider = ({ children }) => {
  const [createChallengeLoading, setCreateChallengeLoading] = useState(null);
  const [updateChallengeLoading, setUpdateChallengeLoading] = useState(null);
  const [deleteChallengeLoading, setDeleteChallengeLoading] = useState(null);

  const [assignChallengeLoading, setAssignChallengeLoading] = useState({
    eventId: null,
    challengeId: null,
  });
  const [unassignChallengeLoading, setUnassignChallengeLoading] = useState({
    eventId: null,
    challengeId: null,
  });

  const [createEventLoading, setCreateEventLoading] = useState(null);
  const [updateEventLoading, setUpdateEventLoading] = useState(null);
  const [deleteEventLoading, setDeleteEventLoading] = useState(null);
  const [startEventLoading, setStartEventLoading] = useState(null);
  const [stopEventLoading, setStopEventLoading] = useState(null);

  return (
    <LoadingContext.Provider
      value={{
        createChallengeLoading,
        setCreateChallengeLoading,
        updateChallengeLoading,
        setUpdateChallengeLoading,
        deleteChallengeLoading,
        setDeleteChallengeLoading,
        assignChallengeLoading,
        setAssignChallengeLoading,
        unassignChallengeLoading,
        setUnassignChallengeLoading,
        createEventLoading,
        setCreateEventLoading,
        updateEventLoading,
        setUpdateEventLoading,
        deleteEventLoading,
        setDeleteEventLoading,
        startEventLoading,
        setStartEventLoading,
        stopEventLoading,
        setStopEventLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
