import React, { useContext } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";

const ChallengeInstructions = ({ challenge }) => {
  const config = useContext(ConfigContext);
  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("ChallengeInstructions", logLevel);

  const activeBackgroundColour = "#cee5f8";
  const pendingBackgroundColour = "#ffeae3";

  Logger.of("ChallengeInstructions").trace(
    "Rendering ChallengeInstructions component"
  );
  Logger.of("ChallengeInstructions").trace("Challenge: ", challenge);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor:
                challenge.status === "validated"
                  ? activeBackgroundColour
                  : pendingBackgroundColour,
            }}
          >
            <TableCell style={{ width: "40%" }}>
              <strong>Instructions</strong>
            </TableCell>
            <TableCell style={{ width: "30%" }}>
              <strong>Infrastructure</strong>
            </TableCell>
            <TableCell style={{ width: "10%" }}>
              <strong>Difficulty</strong>
            </TableCell>
            <TableCell style={{ width: "10%" }}>
              <strong>Points</strong>
            </TableCell>
            <TableCell style={{ width: "10%" }}>
              <strong>Answer</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            style={{
              backgroundColor:
                challenge.status === "validated"
                  ? activeBackgroundColour
                  : pendingBackgroundColour,
            }}
          >
            <TableCell>
              <ol>
                {challenge.instructions.map((instruction, index) => (
                  <li key={index}>{instruction.description}</li>
                ))}
              </ol>
            </TableCell>
            <TableCell>{challenge.infrastructure}</TableCell>
            <TableCell>{challenge.difficulty}</TableCell>
            <TableCell>{challenge.points}</TableCell>
            <TableCell>
              {challenge.answer ? challenge.answer : "No answer provided"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default ChallengeInstructions;
