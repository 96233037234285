import React from "react";

import EventScoreboard from "./EventScoreboard";
import { Typography } from "@mui/material";

const AdminScoreboard = ({ teams, events }) => {
  return (
    <div>
      <Typography my={3} variant="h5">
        <strong>Scoreboard</strong>
      </Typography>
      {events
        .filter((event) => event.status === "started")
        .map((event) => (
          <EventScoreboard event={event} teams={teams} key={event.id} />
        ))}
    </div>
  );
};

export default AdminScoreboard;
