import React, { useState } from "react";

import { signUp } from "aws-amplify/auth";

import ConfirmSignUp from "./ConfirmSignUp";

import InputAdornment from "@mui/material/InputAdornment";
import { TextField, IconButton, Tooltip, Typography } from "@mui/material";

import { AccountCircle, Email } from "@mui/icons-material";

import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { Key, Visibility } from "@mui/icons-material";

import Button from "@mui/material/Button";

const SignUp = ({ setCognitoUser, setShowSignUp }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const [signUpNextStep, setSignUpNextStep] = useState(null);
  const [errors, setErrors] = useState({});

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [signUpError, setSignUpError] = useState(null);

  async function handleSignUp() {
    let newErrors = {};

    if (!firstName.trim()) {
      newErrors.firstName = "First name is a required field";
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last name is a required field";
    }

    if (!email.trim()) {
      newErrors.email = "Email is a required field";
    }

    if (!password.trim()) {
      newErrors.password = "Password is a required field";
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm password is a required field";
    }

    if (password !== confirmPassword) {
      newErrors.passwordMatch = "Passwords do not match";
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    setSignUpError(null);

    try {
      let username = email;
      let given_name = firstName;
      let family_name = lastName;
      const { nextStep } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
            given_name,
            family_name,
          },
          autoSignIn: true,
        },
      });

      setUsername(username);
      setSignUpNextStep(nextStep);
    } catch (error) {
      setSignUpError(error.message);
    }
  }

  function handlePasswordVisibleClick() {
    setPasswordVisible(!passwordVisible);
  }

  function handleConfirmPasswordVisibleClick() {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  }

  function handleBackToLoginClick() {
    setShowSignUp(false);
  }

  return (
    <>
      {!signUpNextStep && (
        <>
          <TextField
            fullWidth
            error={!!errors.firstName}
            type="text"
            name="firstName"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            required
            variant="outlined"
            helperText={errors.firstName ? "Enter your first name" : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            error={!!errors.lastName}
            type="text"
            name="lastName"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            required
            variant="outlined"
            helperText={errors.lastName ? "Enter your last name" : ""}
            sx={{ marginTop: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            error={!!errors.email}
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
            variant="outlined"
            helperText={errors.username ? "Enter your email address" : ""}
            sx={{ marginTop: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            error={!!errors.password}
            type={passwordVisible ? "text" : "password"}
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
            variant="outlined"
            helperText={errors.password ? "Enter your password" : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={passwordVisible ? "Hide password" : "Show Password"}
                  >
                    <Visibility onClick={handlePasswordVisibleClick} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: "20px" }}
          />
          <TextField
            fullWidth
            error={!!errors.confirmPassword}
            type={confirmPasswordVisible ? "text" : "password"}
            name="confirmPassword"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            variant="outlined"
            helperText={
              errors.confirmPassword ? "Enter your password again" : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={passwordVisible ? "Hide password" : "Show Password"}
                  >
                    <Visibility onClick={handleConfirmPasswordVisibleClick} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: "20px" }}
          />
          <div>
            {signUpError && (
              <Typography variant="body1" color="error" align="center" mt={2}>
                {signUpError}
              </Typography>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button onClick={handleBackToLoginClick}>Back to Login</Button>
            <Button variant="contained" onClick={handleSignUp}>
              Register
            </Button>
          </div>
          <Typography mt={2} align="center">
            {" "}
            Or sign up with (coming soon):
          </Typography>
          <Typography mt={2} mb={0} align="center" variant="body1">
            <Tooltip title="Facebook">
              <IconButton
                sx={{
                  color: "#3b5998",
                  marginRight: "10px",
                  marginLeft: "10px",
                  bgcolor: "#ddd",
                  "&:hover": {
                    bgcolor: "#3b5998",
                    color: "#fff",
                  },
                }}
              >
                <FacebookIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Google">
              <IconButton
                sx={{
                  color: "#e64f3c",
                  marginRight: "10px",
                  bgcolor: "#ddd",
                  "&:hover": {
                    bgcolor: "#e64f3c",
                    color: "#fff",
                  },
                }}
              >
                <GoogleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Twitter">
              <IconButton
                sx={{
                  color: "#2398ed",
                  marginRight: "10px",
                  bgcolor: "#ddd",
                  "&:hover": {
                    bgcolor: "#2398ed",
                    color: "#fff",
                  },
                }}
              >
                <TwitterIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="GitHub">
              <IconButton
                sx={{
                  color: "#24292E",
                  marginRight: "10px",
                  bgcolor: "#ddd",
                  "&:hover": {
                    bgcolor: "#24292E",
                    color: "#fff",
                  },
                }}
              >
                <GitHubIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="LinkedIn">
              <IconButton
                sx={{
                  color: "#2c66b7",
                  marginRight: "10px",
                  bgcolor: "#ddd",
                  "&:hover": {
                    bgcolor: "#2c66b7",
                    color: "#fff",
                  },
                }}
              >
                <LinkedInIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      )}
      {signUpNextStep && signUpNextStep.signUpStep === "CONFIRM_SIGN_UP" && (
        <>
          <ConfirmSignUp
            username={username}
            setSignUpNextStep={setSignUpNextStep}
            setCognitoUser={setCognitoUser}
          />
        </>
      )}
    </>
  );
};

export default SignUp;
