import React, { useState } from "react";

import { confirmSignUp, autoSignIn, getCurrentUser } from "aws-amplify/auth";

import { Logger } from "react-logger-lib";

localStorage.setItem(
  "Authentication.ConfirmSignUp.handleConfirmSignInWithNewPassword",
  "ERROR"
);

localStorage.setItem("Authentication.ConfirmSignUp.handleAutoSignIn", "ERROR");

const ConfirmSignUp = ({ username, setSignUpNextStep, setCognitoUser }) => {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [errors, setErrors] = useState({});

  async function handleSignUpConfirmation() {
    let newErrors = {};

    if (!confirmationCode.trim()) {
      newErrors.confirmationCode = "Confirmation code is a required field";
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const { nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });

      setSignUpNextStep(nextStep);

      if (
        nextStep &&
        (nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN" ||
          nextStep.signInStep === "DONE")
      ) {
        handleAutoSignIn();
      }
    } catch (error) {
      Logger.of("Authentication.ConfirmSignUp.handleSignUpConfirmation").error(
        "Error confirming sign up",
        error
      );
    }
  }

  async function handleAutoSignIn() {
    try {
      await autoSignIn();
      setCognitoUser(await getCurrentUser());
    } catch (error) {
      Logger.of("Authentication.ConfirmSignUp.handleSignUpConfirmation").error(
        "Error automatically signing in: ",
        error
      );
    }
  }

  return (
    <>
      <input
        type="text"
        placeholder="Confirmation Code *"
        onChange={(e) => setConfirmationCode(e.target.value)}
      />
      {errors.confirmationCode && (
        <p style={{ color: "red" }}>{errors.confirmationCode}</p>
      )}
      <button onClick={handleSignUpConfirmation}>Submit</button>
    </>
  );
};

export default ConfirmSignUp;
