import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";

import ConfigContext from "../../providers/ConfigContext";
import { WebSocketContext } from "../../providers/WebSocketContext";

import { Logger } from "react-logger-lib";

const UserDetails = ({ currentUser, teams, labEvent }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("UserDashboard.UserDetails.handleSave", logLevel);

  const [name, setName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [teamOption, setTeamOption] = useState("loneWolf");
  const [errors, setErrors] = useState({ name: "" });
  const [loadingMessage, setLoadingMessage] = useState(null);

  const { sendJsonMessage } = useContext(WebSocketContext);

  function handleSave() {
    let newTeam = null;

    let newErrors = { name: "" };

    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    if (teamOption === "startATeam" && !teamName.trim()) {
      newErrors.teamName = "Team name is required to start a new team";
    }

    Logger.of("UserDashboard.UserDetails.handleSave").trace(
      "teamOption:",
      teamOption
    );
    Logger.of("UserDashboard.UserDetails.handleSave").trace("teamId:", teamId);

    if (teamOption === "joinATeam" && teamId === "") {
      newErrors.teamId = "Please select a team";
    }

    if (newErrors.name || newErrors.teamName || newErrors.teamId) {
      setErrors(newErrors);
      return;
    }

    if (teamOption === "loneWolf") {
      newTeam = {
        name: name,
        eventId: labEvent.id,
        members: [currentUser.userId],
        joinable: false,
      };
      setLoadingMessage("Adding lone wolf...");
      createTeam({ team: newTeam });
    } else if (teamOption === "startATeam") {
      newTeam = {
        name: teamName,
        eventId: labEvent.id,
        members: [currentUser.userId],
        joinable: true,
      };
      setLoadingMessage("Creating new team...");
      createTeam({ team: newTeam });
    } else if (teamOption === "joinATeam") {
      let existingTeam = teams.find((team) => team.id === Number(teamId));
      newTeam = {
        ...existingTeam,
        members: [...existingTeam.members, currentUser.userId],
      };
      setLoadingMessage("Joining team...");
      updateTeam({ team: newTeam });
    }

    let updatedUser = {
      ...currentUser,
      name: name,
    };
    Logger.of("UserDashboard.UserDetails.handleSave").trace(
      "updatedUser:",
      updatedUser
    );
    updateUser({ user: updatedUser });
  }

  function updateUser({ user }) {
    Logger.of("UserDashboard.UserDetails.handleSave").trace("user:", user);
    sendJsonMessage({ action: "usersUpdate", user: user });
  }

  function createTeam({ team }) {
    sendJsonMessage({ action: "teamsCreate", team: team });
  }

  function updateTeam({ team }) {
    sendJsonMessage({ action: "teamsUpdate", team: team });
  }

  return (
    <div>
      <p>Welcome, please enter your details to join a team and get started.</p>
      <div>
        <label>
          <TextField
            fullWidth
            type="text"
            name="name"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            required
            label="Name"
            variant="outlined"
          />
        </label>
        {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
      </div>
      {loadingMessage ? (
        <p>{loadingMessage}</p>
      ) : (
        <>
          <RadioGroup
            sx={{ marginTop: "20px" }}
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="loneWolf"
            name="radio-buttons-group"
            onChange={(event) => setTeamOption(event.target.value)}
          >
            <FormControlLabel
              value="loneWolf"
              control={<Radio />}
              label="Lone Wolf"
            />
            <FormControlLabel
              value="startATeam"
              control={<Radio />}
              label="Start a new team"
            />
            <FormControlLabel
              value="joinATeam"
              control={<Radio />}
              label="Join a team"
            />
          </RadioGroup>
          {teamOption === "startATeam" && (
            <>
              <TextField
                fullWidth
                type="text"
                name="teamName"
                placeholder="Team name"
                onChange={(e) => {
                  setTeamName(e.target.value);
                }}
                label="Team name"
                variant="outlined"
              />
              {errors.teamName && (
                <p style={{ color: "red" }}>{errors.teamName}</p>
              )}
            </>
          )}
          {teamOption === "joinATeam" && (
            <>
              <FormControl fullWidth sx={{ marginTop: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select a team
                </InputLabel>
                <Select
                  label="Select a team"
                  value={teamId}
                  required
                  onChange={(e) => setTeamId(Number(e.target.value))}
                >
                  <MenuItem value="" disabled></MenuItem>
                  {teams
                    .filter((team) => team.joinable)
                    .map((team) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {errors.teamId && <p style={{ color: "red" }}>{errors.teamId}</p>}
            </>
          )}
        </>
      )}
      <p>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSave}
          disabled={loadingMessage !== null}
        >
          Save
          {loadingMessage && loadingMessage !== null && (
            <CircularProgress size="1rem" sx={{ marginLeft: "10px" }} />
          )}
        </Button>
      </p>
    </div>
  );
};

export default UserDetails;
