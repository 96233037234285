import React, { useState, useContext } from "react";

import ChallengeActions from "./ChallengeActions";
import ChallengeInstructions from "./ChallengeInstructions";
import EditChallenge from "./EditChallenge";

import EventChallengeActions from "../Events/EventChallengeActions";
import { Box, Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingContext from "../../../providers/LoadingContext";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";

const Challenge = ({
  challenge,
  event,
  activeBackgroundColour,
  pendingBackgroundColour,
  actionType,
  difficulties,
}) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("Challenge", logLevel);

  const [open, setOpen] = useState(false);
  const [showEditChallenge, setShowEditChallenge] = useState(false);

  const { unassignChallengeLoading } = useContext(LoadingContext);

  Logger.of("Challenge").trace("Rendering Challenge component");
  Logger.of("Challenge").trace("Challenge: ", challenge);

  let loadingMessage = null;

  if (
    event &&
    unassignChallengeLoading.eventId === event.id &&
    unassignChallengeLoading.challengeId === challenge.id
  ) {
    loadingMessage = "Unassigning challenge...";
  }

  return (
    <>
      {loadingMessage ? (
        <TableRow
          style={{
            gridColumnStart: 1,
            backgroundColor:
              challenge.status === "validated"
                ? activeBackgroundColour
                : pendingBackgroundColour,
            cursor: "pointer",
          }}
        >
          <TableCell colSpan={5} align="center">
            {loadingMessage}
            <CircularProgress size="1rem" sx={{ marginLeft: "10px" }} />
          </TableCell>
        </TableRow>
      ) : (
        <>
          <TableRow
            style={{
              gridColumnStart: 1,
              backgroundColor:
                challenge.status === "validated"
                  ? activeBackgroundColour
                  : pendingBackgroundColour,
              cursor: "pointer",
            }}
            onClick={() => setOpen(!open)}
          >
            <TableCell width="50px">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </TableCell>
            <TableCell>
              <p>{challenge.name}</p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor:
                  challenge.status === "validated"
                    ? activeBackgroundColour
                    : pendingBackgroundColour,
              }}
            >
              {challenge.description}
            </TableCell>
            <TableCell
              style={{
                backgroundColor:
                  challenge.status === "validated"
                    ? activeBackgroundColour
                    : pendingBackgroundColour,
              }}
            >
              {challenge.status}
            </TableCell>
            <TableCell>
              {actionType === "challenge" && (
                <ChallengeActions
                  challenge={challenge}
                  showEditChallenge={showEditChallenge}
                  setShowEditChallenge={setShowEditChallenge}
                  validatedBackgroundColour={activeBackgroundColour}
                  pendingBackgroundColour={pendingBackgroundColour}
                />
              )}
              {actionType === "event" && (
                <EventChallengeActions
                  challenge={challenge}
                  labEvent={event}
                  validatedBackgroundColour={activeBackgroundColour}
                  pendingBackgroundColour={pendingBackgroundColour}
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ padding: 0, margin: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box>
                  <ChallengeInstructions challenge={challenge} />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          {showEditChallenge && difficulties && (
            <>
              <EditChallenge
                challenge={challenge}
                showEditChallenge={showEditChallenge}
                setShowEditChallenge={setShowEditChallenge}
                difficulties={difficulties}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Challenge;
