import React, { useContext } from "react";

import TeamScore from "./TeamScore";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import ConfigContext from "../../providers/ConfigContext";

//import { WebSocketContext } from "../WebSocketContext";
//import { Logger } from "react-logger-lib";

const UserScoreboard = ({ teams, currentUser }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("Scoreboard.UserScoreboard.useEffect", logLevel);

  //jjconst { sendJsonMessage, lastJsonMessage, readyState } =
  //jj  React.useContext(WebSocketContext);

  //useEffect(() => {
  //  if (lastJsonMessage !== null) {
  //    if (lastJsonMessage.teams) {
  //      Logger.of("Scoreboard.UserScoreboard.useEffect").trace(
  //        "Would update teams with: ",
  //        lastJsonMessage.teams
  //      );
  //    }
  //  }
  //   Use readyStateRef here
  //  //if (readyState === WebSocket.OPEN) {
  //  //  sendJsonMessage({ action: "teams", eventId: activeUserEventId });
  //  //}
  //}, [readyState, sendJsonMessage, activeUserEventId, lastJsonMessage]);

  return (
    <>
      <p>
        <strong>Scoreboard</strong>
      </p>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Team Name</strong>
              </TableCell>
              <TableCell>
                <strong>Score</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams
              .filter((team) => team.eventId === currentUser.eventId)
              .map((team) => (
                <TeamScore team={team} key={team.id} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserScoreboard;
