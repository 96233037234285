import React, { useState, useContext } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";

import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  CardContent,
} from "@mui/material";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";
import { WebSocketContext } from "../../../providers/WebSocketContext";
import LoadingContext from "../../../providers/LoadingContext";

const CreateChallenge = ({
  showCreateChallenge,
  setShowCreateChallenge,
  difficulties,
}) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.CreateChallenge", logLevel);

  const { sendJsonMessage } = useContext(WebSocketContext);

  Logger.of("AdminDashboard.CreateChallenge").trace(
    "Rendering CreateChallenge component"
  );

  Logger.of("AdminDashboard.CreateChallenge").trace(
    "Difficulties: ",
    difficulties
  );

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    instructions: [],
  });
  const [infrastructure, setInfrastructure] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [instructions, setInstructions] = useState([
    {
      step: 1,
      description: "",
      text: "",
    },
  ]);
  const [answer, setAnswer] = useState("");
  const [nameShrink, setNameShrink] = useState(false);
  const [answerShrink, setAnswerShrink] = useState(false);

  const { setCreateChallengeLoading } = useContext(LoadingContext);

  const handleAddInstruction = () => {
    const maxStep =
      instructions.length > 0
        ? Math.max(...instructions.map((instruction) => instruction.step))
        : 0;
    setInstructions((prevInstructions) => [
      ...prevInstructions,
      {
        step: maxStep + 1,
        description: "",
        text: "",
      },
    ]);
  };

  const handleInstructionChange = (e, index, field) => {
    Logger.of("AdminDashboard.EditChallenge.handleInstructionChange").trace(
      "e.target.value: ",
      e.target.value
    );
    const newInstructions = [...instructions];
    newInstructions[index][field] = e.target.value;
    setInstructions(newInstructions);
  };

  function handleAdd(event) {
    let newErrors = { name: "", description: "", instructions: [] };

    if (!name.trim()) {
      newErrors.name = "Challenge name is required";
    }

    if (!description.trim()) {
      newErrors.description = "Description is required";
    }

    instructions.forEach((instruction, index) => {
      if (!instruction.description.trim()) {
        newErrors.instructions[index] = "This instruction is required";
      }
    });

    if (!infrastructure.trim()) {
      newErrors.infrastructure = "Infrastructure is required";
    } else if (!infrastructure.includes("resource")) {
      newErrors.infrastructure = "Infrastructure must contain Terraform code";
    }

    if (
      newErrors.name ||
      newErrors.description ||
      newErrors.infrastructure ||
      newErrors.instructions.length
    ) {
      setErrors(newErrors);
      return;
    }

    setCreateChallengeLoading(true);
    sendJsonMessage({
      action: "challengesCreate",
      challenge: {
        name,
        description,
        status: "pending",
        instructions: instructions,
        difficultyId: difficulty,
        infrastructure: infrastructure,
        answer: answer,
      },
    });

    setName("");
    setDescription("");
    setInstructions([""]);
    setDifficulty(null);
    setInfrastructure("");
    setShowCreateChallenge(!showCreateChallenge);
  }

  function handleCancel(event) {
    if (
      window.confirm(
        "Are you sure you want to cancel creating a new challenge? Your changes will be lost!"
      )
    ) {
      setShowCreateChallenge(!showCreateChallenge);
    }
  }

  function handleDeleteInstruction(index) {
    setInstructions((prevInstructions) =>
      prevInstructions.filter((_, i) => i !== index)
    );
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showCreateChallenge}
        onClose={handleCancel}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Create a new challenge</span>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setShowCreateChallenge(!showCreateChallenge);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <CardContent>
          <Card variant="outlined">
            <CardContent>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: "5px" }}
              >
                <TextField
                  fullWidth
                  error={!!errors.name}
                  variant="outlined"
                  type="text"
                  name="name"
                  placeholder="Challenge Name"
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                      transform: "translate(41px, 17px)",
                    },
                  }}
                  onFocus={() => setNameShrink(true)}
                  onBlur={(e) => {
                    !e.target.value && setNameShrink(false);
                  }}
                  label="Challenge Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmojiEventsIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: nameShrink,
                  }}
                  required
                />
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "0px", marginTop: "15px" }}
              >
                <TextField
                  fullWidth
                  error={!!errors.description}
                  variant="outlined"
                  type="text"
                  name="description"
                  value={description}
                  multiline
                  rows={3}
                  placeholder="Challenge Description"
                  onChange={(e) => setDescription(e.target.value)}
                  label="Challenge Description"
                  required
                />
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <InputLabel id="demo-simple-select-label">
                  Select a difficulty *
                </InputLabel>

                <Select
                  fullWidth
                  label="Select a difficulty *"
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  required
                >
                  <MenuItem value="" disabled></MenuItem>
                  {difficulties.map((difficulty) => (
                    <MenuItem key={difficulty.id} value={difficulty.id}>
                      {difficulty.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                style={{ marginBottom: "15px" }}
                fullWidth
                variant="outlined"
                label="Enter Terraform script here"
                name="terraform"
                placeholder="Enter Terraform script here"
                cols={50}
                rows={3}
                multiline
                onChange={(e) => setInfrastructure(e.target.value)}
              />
              {errors.infrastructure && (
                <p style={{ color: "red" }}>{errors.infrastructure}</p>
              )}
              {instructions.map((instruction, index) => (
                <div key={instruction.step} style={{ display: "flex" }}>
                  <TextField
                    fullWidth
                    style={{ marginBottom: "15px" }}
                    name={`challengeInstruction${index}`}
                    cols={50}
                    multiline
                    rows={3}
                    label={`Instructions for step ${index + 1}`}
                    placeholder={`Instructions for step ${index + 1}`}
                    value={instruction.description}
                    onChange={(e) =>
                      handleInstructionChange(e, index, "description")
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  {index > 0 && (
                    <IconButton onClick={() => handleDeleteInstruction(index)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  {errors.instructions[index] && (
                    <p style={{ color: "red" }}>{errors.instructions[index]}</p>
                  )}
                </div>
              ))}
              <Button
                style={{ marginBottom: 15 }}
                onClick={handleAddInstruction}
              >
                Add Instruction
              </Button>

              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "15px" }}
              >
                <TextField
                  fullWidth
                  error={!!errors.name}
                  variant="outlined"
                  type="text"
                  name="answer"
                  placeholder="Challenge Secret answer"
                  onChange={(e) => setAnswer(e.target.value)}
                  sx={{
                    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                      transform: "translate(41px, 17px)",
                    },
                  }}
                  onFocus={() => setAnswerShrink(true)}
                  onBlur={(e) => {
                    !e.target.value && setAnswerShrink(false);
                  }}
                  label="Challenge Secret Answer"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: answerShrink,
                  }}
                  required
                />
              </FormControl>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" onClick={handleAdd}>
                  Create
                </Button>
              </div>
            </CardContent>
          </Card>
        </CardContent>
      </Dialog>
    </>
  );
};

export default CreateChallenge;
