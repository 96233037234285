import React, { useState, useContext, useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import CreateChallenge from "./CreateChallenge";
import Challenge from "./Challenge";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";
import { WebSocketContext } from "../../../providers/WebSocketContext";
import LoadingContext from "../../../providers/LoadingContext";

const ChallengeList = ({ challenges, setChallenges }) => {
  const config = useContext(ConfigContext);
  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.ChallengeList", logLevel);
  localStorage.setItem("AdminDashboard.ChallengeList.useEffect", logLevel);

  const [showCreateChallenge, setShowCreateChallenge] = useState(false);
  const [difficulties, setDifficulties] = useState(null);
  const [requestedDifficulties, setRequestedDifficulties] = useState(false);
  const [challengeError, setChallengeError] = useState(null);

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useContext(WebSocketContext);

  const {
    createChallengeLoading,
    setUpdateChallengeLoading,
    setCreateChallengeLoading,
    setDeleteChallengeLoading,
  } = useContext(LoadingContext);

  const readyStateRef = useRef(readyState);

  useEffect(() => {
    readyStateRef.current = readyState;
  }, [readyState]);

  useEffect(() => {
    if (readyStateRef.current === WebSocket.OPEN) {
      if (!difficulties && !requestedDifficulties) {
        Logger.of("AdminDashboard.ChallengeList.useEffect").trace(
          "Requesting data from the websocket for all difficulties"
        );
        sendJsonMessage({ action: "difficultiesGet" });
        setRequestedDifficulties(true);
      }
    }
  }, [sendJsonMessage, difficulties, requestedDifficulties]);

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.difficulties) {
        Logger.of("AdminDashboard.ChallengeList.useEffect").trace(
          "Setting difficulties to: ",
          lastJsonMessage.difficulties
        );
        setDifficulties(lastJsonMessage.difficulties);
      }
      if (lastJsonMessage.challengeError) {
        Logger.of("AdminDashboard.ChallengeList.useEffect").trace(
          "Setting challengeError to: ",
          lastJsonMessage.challengeError
        );
        setCreateChallengeLoading(false);
        setDeleteChallengeLoading(false);
        setUpdateChallengeLoading(false);
        setChallengeError(lastJsonMessage.challengeError);
      }
    }
  }, [
    lastJsonMessage,
    setUpdateChallengeLoading,
    setChallengeError,
    setDeleteChallengeLoading,
    setCreateChallengeLoading,
  ]);

  function handleCreateChallengeClick(event) {
    event.preventDefault();
    setShowCreateChallenge(!showCreateChallenge);
  }

  function handleErrorClose() {
    setChallengeError(null);
  }

  Logger.of("ChallengeList").trace("Rendering ChallengeList component");
  Logger.of("ChallengeList").trace("Challenges: ", challenges);

  return (
    <>
      <Typography my={3} variant="h5">
        <strong>Challenges</strong>
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }}></TableCell>
              <TableCell style={{ width: "25%" }}>
                <strong>Name</strong>
              </TableCell>
              <TableCell style={{ width: "45%" }}>
                <strong>Description</strong>
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <strong>Status</strong>
              </TableCell>
              <TableCell style={{ width: "15%" }} align="right">
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {challenges.length === 0 ? (
              <div style={{ gridColumn: "span 2" }}>
                No challenges to display
              </div>
            ) : (
              challenges.map((challenge) => (
                <Challenge
                  key={challenge.id}
                  challenge={challenge}
                  setChallenges={setChallenges}
                  activeBackgroundColour="#cee5d8"
                  pendingBackgroundColour="#ffa9a9"
                  actionType="challenge"
                  difficulties={difficulties}
                />
              ))
            )}
            {createChallengeLoading && (
              <TableRow
                style={{
                  backgroundColor: "#ffa9a9",
                }}
              >
                <TableCell colSpan={5} align="center">
                  <p>Creating challenge...</p>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={5} align="right">
                <Button onClick={handleCreateChallengeClick}>
                  Create new challenge
                </Button>
              </TableCell>
            </TableRow>
            {showCreateChallenge && difficulties && (
              <CreateChallenge
                showCreateChallenge={showCreateChallenge}
                setShowCreateChallenge={setShowCreateChallenge}
                difficulties={difficulties}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {challengeError && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={challengeError}
          onClose={handleErrorClose}
        >
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Oops - can't do that</span>
            <IconButton
              onClick={handleErrorClose}
              edge="end"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <p>{challengeError.message}</p>
            {challengeError.events &&
              challengeError.events.map((event) => (
                <p>Event name: {event.name}</p>
              ))}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ChallengeList;
