import React, { useState, useContext } from "react";

import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Card,
  CardContent,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
} from "@mui/material";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";
import { WebSocketContext } from "../../../providers/WebSocketContext";
import LoadingContext from "../../../providers/LoadingContext";

const EditEvent = ({ event, showEditEvent, setShowEditEvent }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = null;
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.EditEvent", logLevel);

  Logger.of("AdminDashboard.EditEvent").info(
    "Rendering the EditEvent component"
  );

  const { sendJsonMessage } = useContext(WebSocketContext);

  const [name, setName] = useState(event.name);
  const [description, setDescription] = useState(event.description);
  const [errors, setErrors] = useState({ name: false, description: false });

  const { setUpdateEventLoading } = useContext(LoadingContext);

  function handleSave() {
    let newErrors = { name: false, description: false };

    if (!name.trim()) {
      newErrors.name = true;
    }

    if (!description.trim()) {
      newErrors.description = true;
    }

    if (newErrors.name || newErrors.description) {
      setErrors(newErrors);
      return;
    }

    setUpdateEventLoading(event.id);
    sendJsonMessage({
      action: "eventsUpdate",
      event: {
        ...event,
        name,
        description,
      },
    });

    setName("");
    setDescription("");
    setShowEditEvent(!showEditEvent);
  }

  function handleCancel(event) {
    setName("");
    setDescription("");
    setShowEditEvent(!showEditEvent);
  }

  function handleEditEventClose(event) {
    setShowEditEvent(!showEditEvent);
  }

  return (
    <Dialog open={showEditEvent} onClose={handleEditEventClose}>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          Edit the <strong>{event.name}</strong> event
        </span>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setShowEditEvent(!showEditEvent);
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <CardContent style={{ paddingTop: "0px" }}>
        <Card variant="outlined">
          <CardContent>
            <FormControl variant="outlined" fullWidth>
              <TextField
                fullWidth
                error={!!errors.name}
                type="text"
                name="name"
                label="Event name"
                placeholder="Event name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                variant="outlined"
                helperText={errors.name ? "Enter a name for the event" : ""}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <TextField
                fullWidth
                error={!!errors.description}
                type="text"
                name="description"
                placeholder="Event description"
                label="Event description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                multiline
                rows={3}
                variant="outlined"
                helperText={
                  errors.description ? "Enter a description for the event" : ""
                }
                sx={{ marginTop: "20px" }}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" onClick={handleSave}>
                Save event
              </Button>
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Dialog>
  );
};

export default EditEvent;
