const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: "525r7nl37edhr22djtgae3ov3g",
      userPoolId: "ap-southeast-2_x82uZlpvw",
      loginWith: {
        username: true,
      },
    },
  },
};
export default amplifyConfig;
