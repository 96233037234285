import React, { useContext } from "react";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import ConfigContext from "../../../providers/ConfigContext";
import LoadingContext from "../../../providers/LoadingContext";
import { WebSocketContext } from "../../../providers/WebSocketContext";

const ChallengeActions = ({
  challenge,
  showEditChallenge,
  setShowEditChallenge,
}) => {
  const config = useContext(ConfigContext);
  const overrideLogLevel = null; // Set to "TRACE", "INFO", etc to override

  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.Challenges.ChallengeActions", logLevel);

  const { sendJsonMessage } = useContext(WebSocketContext);

  const {
    updateChallengeLoading,
    deleteChallengeLoading,
    setDeleteChallengeLoading,
    setUpdateChallengeLoading,
  } = useContext(LoadingContext);

  function handleEditChallengeClick(event, challengeId) {
    setShowEditChallenge(!showEditChallenge);
  }

  function handleValidateChallengeClick(event, challengeId) {
    let updatedChallenge = {
      ...challenge,
      status: "validated",
    };
    setUpdateChallengeLoading(challengeId);
    sendJsonMessage({
      action: "challengesUpdate",
      challenge: updatedChallenge,
    });
  }

  function handleDeleteChallengeClick(event, challengeId) {
    event.preventDefault();
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this challenge?"
    );
    if (confirmDelete) {
      setDeleteChallengeLoading(challengeId);
      sendJsonMessage({
        action: "challengesDelete",
        challengeId: challengeId,
      });
    }
  }

  let loadingMessage = null;

  if (updateChallengeLoading === challenge.id) {
    loadingMessage = "Updating...";
  } else if (deleteChallengeLoading === challenge.id) {
    loadingMessage = "Deleting...";
  }

  return (
    <>
      {loadingMessage ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Typography>{loadingMessage}</Typography>
          <CircularProgress size="1rem" sx={{ marginLeft: "10px" }} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleEditChallengeClick(event, challenge.id);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteChallengeClick(event, challenge.id);
              }}
            >
              <CloseIcon />
            </IconButton>
            {challenge.status === "pending" && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleValidateChallengeClick(event, challenge.id);
                }}
              >
                <DoneIcon />
              </IconButton>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default ChallengeActions;
