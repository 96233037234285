import React, { useState, useEffect, useRef, useContext } from "react";

import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../providers/ConfigContext";

const JoinEvent = ({ currentUser, setCurrentUser, events }) => {
  const config = useContext(ConfigContext);
  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("UserDashboard.JoinEvent.useEffect", logLevel);

  Logger.of("UserDashboard.JoinEvent").trace("Rendering JoinEvent component");

  const [joinCode, setJoinCode] = useState("");
  const [errors, setErrors] = useState({});

  const handleJoinEventClick = () => {
    const matchingEvent = events.find((event) => event.joinCode === joinCode);

    let newErrors = { joinCode: false };

    if (matchingEvent) {
      setCurrentUser({
        ...currentUser,
        eventId: matchingEvent.id,
      });
      setErrors({ joinCode: false });
    } else {
      newErrors.joinCode = true;
    }

    if (newErrors.joinCode) {
      setErrors(newErrors);
    }
  };

  return (
    <Container maxWidth="sm">
      <Card variant="outlined">
        <CardContent>
          {events ? (
            <>
              {config.demoMode && (
                <>
                  <Typography variant="h5" paragraph>
                    Demo mode enabled
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Login with the following codes:
                  </Typography>
                  {events
                    .filter((event) => event.status === "started")
                    .map((event) => (
                      <Grid container sx={{ marginTop: "20px" }} key={event.id}>
                        <Grid item xs={6}>
                          <Typography variant="body2">Event name:</Typography>
                          <Typography variant="body2" sx={{ marginTop: "8px" }}>
                            {event.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">Join code:</Typography>
                          <Typography
                            variant="body2"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {event.joinCode}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </>
              )}
              <TextField
                type="text"
                name="code"
                error={!!errors.joinCode}
                variant="outlined"
                fullWidth
                onChange={(e) => setJoinCode(Number(e.target.value))}
                helperText={errors.joinCode ? "Invalid code" : ""}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleJoinEventClick();
                  }
                }}
                label="Enter your code"
                sx={{ marginTop: "16px" }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                onClick={handleJoinEventClick}
                style={{ marginTop: "16px" }}
              >
                Login
              </Button>
            </>
          ) : (
            <Typography variant="h5" gutterBottom>
              Loading events...
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default JoinEvent;
