import React from "react";
import Button from "@mui/material/Button";

import CreateEvent from "./CreateEvent";

import Event from "./Event";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import LoadingContext from "../../../providers/LoadingContext";

const EventList = ({
  events,
  challenges,
  setChallenges,
  challengeDifficulties,
}) => {
  const [showCreateEvent, setShowCreateEvent] = React.useState(false);
  const [activeEventId, setActiveEventId] = React.useState(null);

  const { createEventLoading } = React.useContext(LoadingContext);

  function handleCreateEventClick(event) {
    event.preventDefault();
    setActiveEventId(null);
    setShowCreateEvent(!showCreateEvent);
  }

  return (
    <>
      <Typography my={3} variant="h5">
        <strong>All events</strong>
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }}></TableCell>
              <TableCell style={{ width: "25%" }}>
                <strong>Name</strong>
              </TableCell>
              <TableCell style={{ width: "45%" }}>
                <strong>Description</strong>
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <strong>Status</strong>
              </TableCell>
              <TableCell style={{ width: "15%" }} align="right">
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((labEvent) => (
              <Event
                key={labEvent.id}
                event={labEvent}
                challenges={challenges}
                setChallenges={setChallenges}
                activeEventId={activeEventId}
                setActiveEventId={setActiveEventId}
                challengeDifficulties={challengeDifficulties}
              />
            ))}
            {createEventLoading && (
              <TableRow
                style={{
                  backgroundColor: "#ffa9a9",
                }}
              >
                <TableCell colSpan={6} align="center">
                  <p>
                    Creating new event...
                    <CircularProgress size="1rem" sx={{ marginLeft: "10px" }} />
                  </p>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={6} align="right">
                <Button onClick={handleCreateEventClick}>
                  Create new event
                </Button>
              </TableCell>
            </TableRow>
            {showCreateEvent && (
              <CreateEvent
                showCreateEvent={showCreateEvent}
                setShowCreateEvent={setShowCreateEvent}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EventList;
