import React, { useState } from "react";

import { signIn, confirmSignUp } from "aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";

import SignUp from "./SignUp";

import InputAdornment from "@mui/material/InputAdornment";
import {
  Container,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { AccountCircle, Key, Visibility } from "@mui/icons-material";

import ConfirmSignUp from "./ConfirmSignUp";

import Button from "@mui/material/Button";

import { Logger } from "react-logger-lib";

localStorage.setItem(
  "Authentication.SignIn.handleConfirmSignInWithNewPassword",
  "ERROR"
);

const SignIn = ({ setCognitoUser }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [signInNextStep, setSignInNextStep] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState(null);

  async function handleSignIn() {
    let newErrors = { username: false, password: false };

    if (!username.trim()) {
      newErrors.username = true;
    }

    if (!password.trim()) {
      newErrors.password = true;
    }

    if (newErrors.username || newErrors.password) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({ username: false, password: false });
    }

    setLoginError(null);

    try {
      const { nextStep } = await signIn({
        username,
        password,
      });
      setSignInNextStep(nextStep);
      if (nextStep.signInStep === "DONE") {
        setCognitoUser(await getCurrentUser());
      }
    } catch (error) {
      setLoginError(error.message);
    }
  }

  async function handleConfirmSignInWithNewPassword() {
    try {
      const { nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });

      setSignInNextStep(nextStep);
    } catch (error) {
      Logger.of(
        "Authentication.SignIn.handleConfirmSignInWithNewPassword"
      ).error("Error confirming sign up with new password", error);
    }
  }

  function handlePasswordVisibleClick() {
    setPasswordVisible(!passwordVisible);
  }

  function handleRegisterClick() {
    setShowSignUp(!showSignUp);
  }

  return (
    <CardContent>
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent>
            {!signInNextStep && showSignUp && (
              <SignUp
                setCognitoUser={setCognitoUser}
                setShowSignUp={setShowSignUp}
              />
            )}
            {!signInNextStep && !showSignUp && (
              <>
                <TextField
                  fullWidth
                  error={!!errors.username}
                  type="text"
                  name="username"
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  variant="outlined"
                  helperText={errors.username ? "Enter your username" : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  error={!!errors.password}
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  variant="outlined"
                  helperText={errors.password ? "Enter your password" : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Key />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            passwordVisible ? "Hide password" : "Show Password"
                          }
                        >
                          <Visibility onClick={handlePasswordVisibleClick} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ marginTop: "10px" }}
                />
                <div>
                  {loginError && (
                    <Typography
                      variant="body1"
                      color="error"
                      align="center"
                      mt={2}
                    >
                      {loginError}
                    </Typography>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                  <Button variant="contained" onClick={handleSignIn}>
                    Login
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button onClick={handleRegisterClick}>Register now</Button>
                  <Button>Forgot password?</Button>
                </div>
                <Typography mt={2} align="center">
                  {" "}
                  Or login with (coming soon):
                </Typography>
                <Typography mt={2} mb={0} align="center" variant="body1">
                  <Tooltip title="Facebook">
                    <IconButton
                      sx={{
                        color: "#3b5998",
                        marginRight: "10px",
                        marginLeft: "10px",
                        bgcolor: "#ddd",
                        "&:hover": {
                          bgcolor: "#3b5998",
                          color: "#fff",
                        },
                      }}
                    >
                      <FacebookIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Google">
                    <IconButton
                      sx={{
                        color: "#e64f3c",
                        marginRight: "10px",
                        bgcolor: "#ddd",
                        "&:hover": {
                          bgcolor: "#e64f3c",
                          color: "#fff",
                        },
                      }}
                    >
                      <GoogleIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Twitter">
                    <IconButton
                      sx={{
                        color: "#2398ed",
                        marginRight: "10px",
                        bgcolor: "#ddd",
                        "&:hover": {
                          bgcolor: "#2398ed",
                          color: "#fff",
                        },
                      }}
                    >
                      <TwitterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="GitHub">
                    <IconButton
                      sx={{
                        color: "#24292E",
                        marginRight: "10px",
                        bgcolor: "#ddd",
                        "&:hover": {
                          bgcolor: "#24292E",
                          color: "#fff",
                        },
                      }}
                    >
                      <GitHubIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="LinkedIn">
                    <IconButton
                      sx={{
                        color: "#2c66b7",
                        marginRight: "10px",
                        bgcolor: "#ddd",
                        "&:hover": {
                          bgcolor: "#2c66b7",
                          color: "#fff",
                        },
                      }}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </>
            )}
            {signInNextStep &&
              signInNextStep.signInStep ===
                "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" && (
                <>
                  <input
                    type="text"
                    name="confirmationCode"
                    placeholder="Confirmation code"
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                  <Button onClick={handleConfirmSignInWithNewPassword}>
                    Submit
                  </Button>
                </>
              )}
            {signInNextStep &&
              signInNextStep.signInStep === "CONFIRM_SIGN_UP" && (
                <ConfirmSignUp
                  username={username}
                  setNextStep={setSignInNextStep}
                  setCognitoUser={setCognitoUser}
                />
              )}
          </CardContent>
        </Card>
      </Container>
    </CardContent>
  );
};

export default SignIn;
