import React, { useState, useEffect } from "react";

import { Amplify } from "aws-amplify";
import { Logger } from "react-logger-lib";

import { WebSocketContext } from "./providers/WebSocketContext";

import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";

import Dashboards from "./Dashboards";
import "./App.css";
import { AppBar, Card, Container, Typography } from "@mui/material";

import SignIn from "./components/Authentication/SignIn";
import SignOut from "./components/Authentication/SignOut";
import amplifyConfig from "./amplifyConfig";

import useWebSocket from "react-use-websocket";

import ConfigContext from "./providers/ConfigContext";

import LoadingProvider from "./providers/LoadingProvider";

import "./App.css";
import config from "./config";

Amplify.configure(amplifyConfig);

function App() {
  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("App", logLevel);
  localStorage.setItem("App.useEffect", logLevel);
  localStorage.setItem("App.useEffect.updateCurrentUser", logLevel);
  localStorage.setItem("App.fetchUserDetails", logLevel);

  const [currentUser, setCurrentUser] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [socketUrl, setSocketUrl] = useState(null);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: (closeEvent) => true,
      share: true,
    }
  );

  useEffect(() => {
    Logger.of("App.useEffect").trace("readyState: ", readyState);
  }, [readyState]);

  const fetchUserDetails = async (idToken, cognitoId) => {
    Logger.of("App.fetchUserDetails").trace("idToken: ", idToken);
    const userResponse = await fetch(config.apiUrl + "/users/" + cognitoId, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return await userResponse.json();
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      Logger.of("App.useEffect").trace(
        "Received a message from the server:",
        lastJsonMessage
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    const checkUserSession = async () => {
      Logger.of("App.useEffect.checkUserSession").trace(
        "Checking user session"
      );
      try {
        let user = await getCurrentUser();
        Logger.of("App.useEffect.checkUserSession").trace("user: ", user);
        setCognitoUser(user);
      } catch (error) {
        Logger.of("App.useEffect").trace(error);
        setCognitoUser(null);
      }
    };

    checkUserSession();
  }, []);

  useEffect(() => {
    const updateIdToken = async () => {
      if (cognitoUser) {
        const currentIdToken = (await fetchAuthSession()).tokens.idToken;
        setIdToken(currentIdToken);
      }
    };
    updateIdToken();
  }, [cognitoUser]); // This effect runs whenever cognitoUser changes

  useEffect(() => {
    const updateSocketUrl = async () => {
      if (!idToken) {
        return;
      }
      setSocketUrl(config.wsUrl + "?bearerToken=" + idToken);
    };
    updateSocketUrl();
  }, [idToken]);

  useEffect(() => {
    const updateCurrentUser = async () => {
      Logger.of("App.useEffect.updateCurrentUser").trace("idToken: ", idToken);
      Logger.of("App.useEffect.updateCurrentUser").trace(
        "cognitoUser: ",
        cognitoUser
      );
      if (!idToken || !cognitoUser) {
        return;
      }

      let userDetails = await fetchUserDetails(idToken, cognitoUser.userId);
      let loggedInUser = cognitoUser;

      loggedInUser.teamId = userDetails.team_id;
      loggedInUser.eventId = userDetails.event_id;
      loggedInUser.name = userDetails.name;
      loggedInUser.isAdmin = userDetails.isAdmin;
      setCurrentUser(loggedInUser);
    };
    updateCurrentUser();
  }, [cognitoUser, idToken]);

  return (
    <ConfigContext.Provider value={config}>
      <WebSocketContext.Provider
        value={{ sendJsonMessage, lastJsonMessage, readyState }}
      >
        <Container elevation={2}>
          <AppBar position="static">
            <Typography variant="h5" style={{ padding: 10 }} align="center">
              Practical Cloud Labs Dashboard
            </Typography>
          </AppBar>
          <Card sx={{ boxShadow: 1 }} variant="outlined">
            <Container maxWidth="sm">
              <Card sx={{ boxShadow: 0 }}>
                {cognitoUser && (
                  <SignOut
                    cognitoUser={cognitoUser}
                    setCurrentUser={setCurrentUser}
                    setCognitoUser={setCognitoUser}
                    setIdToken={setIdToken}
                    setSocketUrl={setSocketUrl}
                  />
                )}
              </Card>
            </Container>
            {currentUser && readyState === 1 && (
              <>
                <LoadingProvider>
                  <Dashboards
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    idToken={idToken}
                  />
                </LoadingProvider>
              </>
            )}
            {!cognitoUser && <SignIn setCognitoUser={setCognitoUser} />}
          </Card>
        </Container>
      </WebSocketContext.Provider>
    </ConfigContext.Provider>
  );
}

export default App;
