import React, { useState, useEffect, useContext, useRef } from "react";

import EventList from "./Events/EventList";
import ChallengeList from "./Challenges/ChallengeList";
import AdminScoreboard from "../Scoreboard/AdminScoreboard";
import { Tab, Tabs, Typography } from "@mui/material";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../providers/ConfigContext";
import { WebSocketContext } from "../../providers/WebSocketContext";

import LoadingContext from "../../providers/LoadingContext";

const AdminDashboard = () => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.Dashboard", logLevel);

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useContext(WebSocketContext);

  const [activeTab, setActiveTab] = useState("eventManagement");
  const [events, setEvents] = useState(null);
  const [requestedEvents, setRequestedEvents] = useState(false);
  const [requestedTeams, setRequestedTeams] = useState(false);
  const [requestedChallenges, setRequestedChallenges] = useState(false);
  const [teams, setTeams] = useState(null);
  const [challenges, setChallenges] = useState(null);

  const {
    setCreateChallengeLoading,
    setUpdateChallengeLoading,
    setDeleteChallengeLoading,
    setAssignChallengeLoading,
    setUnassignChallengeLoading,
    setCreateEventLoading,
    setUpdateEventLoading,
    setDeleteEventLoading,
    setStartEventLoading,
    setStopEventLoading,
  } = useContext(LoadingContext);

  const readyStateRef = useRef(readyState);

  useEffect(() => {
    readyStateRef.current = readyState;
  }, [readyState]);

  useEffect(() => {
    if (readyStateRef.current === WebSocket.OPEN) {
      if (!events && !requestedEvents) {
        Logger.of("AdminDashboard.Dashboard.useEffect").trace(
          "Requesting data from the websocket for all events"
        );
        sendJsonMessage({ action: "eventsGet" });
        setRequestedEvents(true);
      }
      if (!teams && !requestedTeams) {
        Logger.of("AdminDashboard.Dashboard.useEffect").trace(
          "Requesting data from the websocket for all teams"
        );
        sendJsonMessage({ action: "teamsGet" });
        setRequestedTeams(true);
      }
      if (!challenges && !requestedChallenges) {
        Logger.of("AdminDashboard.Dashboard.useEffect").trace(
          "Requesting data from the websocket for all challenges"
        );
        sendJsonMessage({ action: "challengesGet" });
        setRequestedChallenges(true);
      }
    }
  }, [
    events,
    requestedEvents,
    teams,
    requestedTeams,
    sendJsonMessage,
    challenges,
    requestedChallenges,
  ]);

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.events) {
        Logger.of("Dashboards.useEffect").trace(
          "Setting events to: ",
          lastJsonMessage.events
        );
        setAssignChallengeLoading({
          eventId: null,
          challengeId: null,
        });
        setUnassignChallengeLoading({
          eventId: null,
          challengeId: null,
        });
        setCreateEventLoading(null);
        setUpdateEventLoading(null);
        setDeleteEventLoading(null);
        setStartEventLoading(null);
        setStopEventLoading(null);
        setEvents(lastJsonMessage.events);
      }
      if (lastJsonMessage.teams) {
        Logger.of("Dashboards.useEffect").trace(
          "Setting teams to: ",
          lastJsonMessage.teams
        );
        setTeams(lastJsonMessage.teams);
      }
      if (lastJsonMessage.challenges) {
        Logger.of("Dashboards.useEffect").trace(
          "Setting challenges to: ",
          lastJsonMessage.challenges
        );
        setCreateChallengeLoading(false);
        setUpdateChallengeLoading(false);
        setDeleteChallengeLoading(false);
        setChallenges(lastJsonMessage.challenges);
      }
    }
  }, [
    lastJsonMessage,
    setAssignChallengeLoading,
    setUnassignChallengeLoading,
    setCreateEventLoading,
    setUpdateEventLoading,
    setDeleteEventLoading,
    setStartEventLoading,
    setStopEventLoading,
    setCreateChallengeLoading,
    setUpdateChallengeLoading,
    setDeleteChallengeLoading,
  ]);

  return (
    <>
      {events && challenges && teams ? (
        <>
          <Typography variant="h5" align="center">
            <strong>Welcome to the admin dashboard</strong>
          </Typography>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            centered
          >
            <Tab label="Event Management" value="eventManagement" />
            <Tab label="Challenge Management" value="challengeManagement" />
            <Tab label="Scoreboard" value="scoreboard" />
          </Tabs>
          {activeTab === "eventManagement" && events && challenges && (
            <EventList
              events={events}
              challenges={challenges}
              setChallenges={setChallenges}
            />
          )}
          {activeTab === "challengeManagement" && (
            <ChallengeList
              challenges={challenges}
              setChallenges={setChallenges}
            />
          )}
          {activeTab === "scoreboard" && (
            <AdminScoreboard events={events} teams={teams} />
          )}
        </>
      ) : (
        <Typography variant="h5" align="center">
          Loading...
        </Typography>
      )}
    </>
  );
};

export default AdminDashboard;
