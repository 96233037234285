import React from "react";

import Challenge from "../Challenges/Challenge";

import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import AssignChallenge from "../Challenges/AssignChallenge";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";
import LoadingContext from "../../../providers/LoadingContext";

const EventChallenges = ({ labEvent, setChallenges, challenges }) => {
  const config = React.useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.EventChallenges", logLevel);

  const [showChallengeList, setShowChallengeList] = React.useState(false);
  const [activeChallengeId, setActiveChallengeId] = React.useState(null);
  const activeChallengeBackgroundColour = "#cee5f8";
  const pendingChallengeBackgroundColour = "#ffeae3";

  const { assignChallengeLoading } = React.useContext(LoadingContext);

  Logger.of("AdminDashboard.EventChallenges").trace("labEvent: ", labEvent);
  Logger.of("AdminDashboard.EventChallenges").trace("Challenges: ", challenges);

  const unassignedChallenges = challenges.filter(
    (challenge) =>
      !labEvent.challenges.find(
        (eventChallenge) => eventChallenge.id === challenge.id
      )
  );

  function handleAddNewChallengeClick(event) {
    setShowChallengeList(!showChallengeList);
  }

  Logger.of("AdminDashboard.EventChallenges").trace(
    "unassignedChallenges: ",
    unassignedChallenges
  );

  let loadingMessage = null;

  if (assignChallengeLoading.eventId === labEvent.id) {
    loadingMessage = "Assigning Challenge...";
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: activeChallengeBackgroundColour,
            }}
          >
            <TableCell style={{ width: "5%" }}></TableCell>
            <TableCell style={{ width: "25%" }}>
              <strong>Challenge Name</strong>
            </TableCell>
            <TableCell style={{ width: "45%" }}>
              <strong>Description</strong>
            </TableCell>
            <TableCell style={{ width: "10%" }}>
              <strong>Status</strong>
            </TableCell>
            <TableCell style={{ width: "15%" }} align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {labEvent.challenges.length === 0 ? (
            <>
              <TableRow
                style={{
                  backgroundColor: activeChallengeBackgroundColour,
                }}
              >
                {showChallengeList ? (
                  <>
                    {unassignedChallenges.length > 0 ? (
                      <AssignChallenge
                        labEvent={labEvent}
                        unassignedChallenges={unassignedChallenges}
                        showChallengeList={showChallengeList}
                        setShowChallengeList={setShowChallengeList}
                      />
                    ) : (
                      <>
                        <TableCell colSpan={5} align="center">
                          No available Challenges to assign.
                        </TableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {loadingMessage ? (
                      <TableCell colSpan={5} align="center">
                        {loadingMessage}
                        <CircularProgress
                          size="1rem"
                          sx={{ marginLeft: "10px" }}
                        />
                      </TableCell>
                    ) : (
                      <>
                        <TableCell colSpan={4} align="center">
                          No Challenges assigned yet
                        </TableCell>
                        <TableCell align="right">
                          {!showChallengeList && (
                            <IconButton onClick={handleAddNewChallengeClick}>
                              <AddIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}
                  </>
                )}
              </TableRow>
            </>
          ) : (
            <>
              {labEvent.challenges.map((challenge) => {
                return (
                  <Challenge
                    key={challenge.id}
                    challenge={challenge}
                    event={labEvent}
                    setChallenges={setChallenges}
                    activeChallengeId={activeChallengeId}
                    setActiveChallengeId={setActiveChallengeId}
                    activeBackgroundColour={activeChallengeBackgroundColour}
                    pendingBackgroundColour={pendingChallengeBackgroundColour}
                    actionType="event"
                  />
                );
              })}
              <TableRow
                style={{
                  backgroundColor: activeChallengeBackgroundColour,
                }}
              >
                {showChallengeList ? (
                  <>
                    {unassignedChallenges.length > 0 ? (
                      <AssignChallenge
                        labEvent={labEvent}
                        unassignedChallenges={unassignedChallenges}
                        showChallengeList={showChallengeList}
                        setShowChallengeList={setShowChallengeList}
                      />
                    ) : (
                      <>
                        <TableCell colSpan={5} align="center">
                          No available Challenges to assign.
                        </TableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {loadingMessage ? (
                      <TableCell colSpan={5} align="center">
                        {loadingMessage}
                        <CircularProgress
                          size="1rem"
                          sx={{ marginLeft: "10px" }}
                        />
                      </TableCell>
                    ) : (
                      <>
                        {unassignedChallenges.length > 0 ? (
                          <TableCell colSpan={5} align="right">
                            <IconButton onClick={handleAddNewChallengeClick}>
                              <AddIcon />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell colSpan={5} align="center">
                            No more Challenges to assign.
                          </TableCell>
                        )}
                      </>
                    )}
                  </>
                )}
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default EventChallenges;
