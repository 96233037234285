import React, { useContext } from "react";

import ChallengeList from "./ChallengeList";
import UserDetails from "./UserDetails";

import ConfigContext from "../../providers/ConfigContext";

import { Logger } from "react-logger-lib";

const UserDashboard = ({
  events,
  currentUser,
  setCurrentUser,
  teams,
  setTeams,
  idToken,
}) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("UserDashboard.Dashboard", logLevel);

  Logger.of("UserDashboard.Dashboard").trace("events:", events);
  Logger.of("UserDashboard.Dashboard").trace(
    "currentUser.teamId:",
    currentUser.teamId
  );

  const event = events.find((event) => {
    return event.id === currentUser.eventId;
  });

  Logger.of("UserDashboard.Dashboard").trace("event:", event);
  Logger.of("UserDashboard.Dashboard").trace("currentUser:", currentUser);

  return (
    <div>
      {currentUser.teamId && currentUser.name ? (
        <>
          <p>
            Welcome {currentUser.name}, you are part of the team:{" "}
            {teams.find((team) => team.id === currentUser.teamId).name}
          </p>
          <ChallengeList
            event={event}
            teamId={currentUser.teamId}
            teams={teams}
            eventId={currentUser.eventId}
          />
        </>
      ) : (
        <UserDetails
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          teams={teams}
          setTeams={setTeams}
          labEvent={event}
          idToken={idToken}
        />
      )}
    </div>
  );
};

export default UserDashboard;
