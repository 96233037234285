import React, { useContext } from "react";

import EventActions from "./EventActions";
import EditEvent from "./EditEvent";
import EventChallenges from "./EventChallenges";

import { Box, Collapse, IconButton, TableCell, TableRow } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Logger } from "react-logger-lib";
import ConfigContext from "../../../providers/ConfigContext";

const Event = ({ event, challenges, setChallenges }) => {
  const config = useContext(ConfigContext);

  const overrideLogLevel = "TRACE"; // Set to "TRACE", "INFO", etc to override
  const logLevel = overrideLogLevel || config.logLevel;

  localStorage.setItem("AdminDashboard.Event", logLevel);

  const [showEditEvent, setShowEditEvent] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  Logger.of("AdminDashboard.Event").trace("Event: ", event);
  Logger.of("AdminDashboard.Event").trace("Challenges: ", challenges);

  return (
    <>
      <TableRow
        style={{
          backgroundColor: event.status === "started" ? "#cee5d8" : "#ffa9a9",
          gridColumnStart: 1,
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <p>{event.name}</p>
        </TableCell>
        <TableCell>
          <p>{event.description}</p>
        </TableCell>
        <TableCell>
          <p>{event.status}</p>
        </TableCell>
        <TableCell width="100px">
          <EventActions labEvent={event} setShowEditEvent={setShowEditEvent} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <EventChallenges
                labEvent={event}
                setChallenges={setChallenges}
                challenges={challenges}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {showEditEvent && (
        <>
          <EditEvent
            event={event}
            showEditEvent={showEditEvent}
            setShowEditEvent={setShowEditEvent}
          />
        </>
      )}
    </>
  );
};

export default Event;
